import { TitleTypo } from '@/components/atoms/TitleTypo';
import ZodiacCard from '@/components/molecules/ZodiacCard';
import {
  cardsData,
  carouselProps,
} from '@/components/organisms/SectionZodiac/data';

import Carrousel from '../Carrousel';
import './styles.scss';

interface SectionZodiacProps {
  title?: string;
  type: 'carousel' | 'grid';
  bgColor?: 'red' | 'white';
}

const SectionZodiac = ({
  title,
  type,
  bgColor = 'red',
}: SectionZodiacProps) => {
  return (
    <div className={`zodiac-carousel__container bg-color--${bgColor} ${type}`}>
      {title && <TitleTypo color="white-900">{title}</TitleTypo>}
      {type === 'grid' &&
        cardsData.map((zodiacAnimal) => {
          const [id, data] = zodiacAnimal;
          return <ZodiacCard key={id} zodiacAnimal={data} isCarousel={false} />;
        })}
      {type === 'carousel' && (
        <Carrousel
          {...carouselProps}
          indicatorColor={bgColor === 'red' ? 'light' : 'red'}
        >
          {Array.isArray(cardsData) &&
            cardsData.map((zodiacAnimal) => {
              const [id, data] = zodiacAnimal;
              return (
                <ZodiacCard key={id} zodiacAnimal={data} isCarousel={true} />
              );
            })}
        </Carrousel>
      )}
    </div>
  );
};

export default SectionZodiac;
